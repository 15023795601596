import { ref, onMounted } from 'vue';
import QrcodeVue from 'qrcode.vue';
import Cookies from 'js-cookie';
import { useIsMobile } from '../../js/composables/is-mobile';


export default {
  __name: 'qr-code',
  props: {
	link: {
		type: String
	},
	size: {
		type: Number
	},
	useGoogleId: {
		type: Boolean,
		default: false
	},
	buttonText: {
		type: String
	}
},
  setup(__props) {

const props = __props

const GA_COOKIE_NAME = '_ga';
const GA_CONTAINER_PREFIX = '_ga_';



const { isMobile } = useIsMobile();
const qrCodeLink = ref(props.link);

const getClientId = () => {
	const gaCookie = Cookies.get(GA_COOKIE_NAME);
	if (!gaCookie) return null;

	const gaParts = gaCookie.split('.');
	return gaParts.length > 2 ? `${gaParts[2]}.${gaParts[3]}` : null;
};

const getSessionId = () => {
	const cookies = Cookies.get();
	const gaContainerCookie = Object.entries(cookies).find(([name]) => name.startsWith(GA_CONTAINER_PREFIX) && name !== GA_COOKIE_NAME);

	if (!gaContainerCookie) return null;

	const [, cookieValue] = gaContainerCookie;
	const cookieValueParts = cookieValue.split('.');
	return cookieValueParts.length > 2 ? cookieValueParts[2] : null;
};

onMounted(() => {
	if (props.useGoogleId) {
		const clientId = getClientId();
		const sessionId = getSessionId();

		const url = new URL(props.link);

		if (clientId) url.searchParams.append('clientId', clientId);
		if (sessionId) url.searchParams.append('sessionId', sessionId);

		qrCodeLink.value = url.toString();
	}
});

return { __sfc: true,GA_COOKIE_NAME, GA_CONTAINER_PREFIX, props, isMobile, qrCodeLink, getClientId, getSessionId, ref, onMounted, QrcodeVue, Cookies, useIsMobile }
}

}